<template>
  <b-modal
    v-model="data.showModal"
    size="md"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        Account Roles
      </div>
    </div>

    <!-- Modal content -->
    <div class="flex flex-col gap-2">
      <table class="table table-google table-highlight" :class="{ 'table-busy': isLoading }">
        <tbody>
          <template v-for="(role, index) in roles">
            <tr
              v-if="index === 0 || roles[index].category !== roles[index - 1].category"
              :key="role.category"
              class="bg-gray-100 font-bold"
            >
              <td colspan="2">{{ role.category }}</td>
            </tr>
            <tr :key="role.id">
              <td>
                <div class="text-right" :class="{ 'font-bold': role.value }">
                  {{ role.description }}
                </div>
              </td>
              <td class="row-fit">
                <button-select
                  v-model="role.value"
                  :options="options.boolean"
                  size="sm"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-content-between">
        <div>
          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading || !canSave"
            @click="onSave"
          >
            Save
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'AccountRoles',
  components: {
    ButtonSelect,
  },
  props: {
    data: Object,
    id: Number,
  },
  computed: {
    roleIds() {
      return this.roles.filter((role) => role.value === true).map((role) => role.id);
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {
        boolean: [
          { value: false, text: 'No' },
          { value: true, text: 'Yes' },
        ],
      },
      roles: null,
    };
  },
  methods: {
    prepareRoles(roleIds) {
      if (this.roles === null) {
        setTimeout(() => {
          console.log('waiting');
          this.prepareRoles(roleIds);
        }, 100);
        return;
      }
      this.roles.forEach((role) => {
        if (roleIds.includes(role.id)) {
          role.value = true;
        }
      });
    },
    fetchAccountRoles() {
      this.loadingCount++;
      this.$http
        .get(`/accounts/${this.id}/roles`)
        .then((res) => {
          this.prepareRoles(res.body);
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch account roles: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRoles() {
      this.loadingCount++;
      this.$http
        .get('/roles')
        .then((res) => {
          this.roles = res.body.map((role) => {
            role.value = false;
            return role;
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch roles: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchRoles();
      this.fetchAccountRoles();
    },
    onSave() {
      this.loadingCount++;
      this.$http
        .post(`/accounts/${this.id}/roles`)
        .send({ roleIds: this.roleIds })
        .then(() => {
          this.$emit('updated');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to send account roles: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
